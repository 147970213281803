import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Service from './Service';
import Contact from './Contact';
import Testimonial from './Testimonial';
import Project from './Project';
import Footer from './Footer';

function App() {
  const [header, setHeader] = useState(false);

  const scrollPage = () => {
    if (window.scrollY > 100) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  window.addEventListener('scroll', scrollPage);
  return (
    <div className="App" id="page-top">
      <nav
        className={
          header
            ? 'navbar active navbar-expand-lg navbar-dark fixed-top'
            : 'navbar navbar-expand-lg navbar-dark fixed-top'
        }
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand js-scroll-trigger" href="#page-top">
            <img src="assets/img/DCX-logo.svg" alt="" />
          </a>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link js-scroll-trigger navbar-box-margin"
                  href="#contact1"
                >
                  <span className="show-large">CONTACT US</span>
                  <span className="show-mobile">
                    <img src="assets/img/envelope-contactUs.svg"></img>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="masthead">
        <div className="container">
          <div className="masthead-subheading">
            WELCOME TO <span style={{ fontWeight: 'bold' }}>DCX</span>
          </div>
          <div className="masthead-heading">
            <span className="text-uppercase">
              {' '}
              PROVIDING YOUR BUSINESS A DIGITAL EDGE <br />
              TO ENHANCE YOUR CUSTOMER'S XPERIENCE
            </span>
            <br />
            <br />
            <div
              className="masthead-extra-heading"
              style={{ fontWeight: 'normal' }}
            >
              Our approach will help you develop your business idea into
              reality.
            </div>
          </div>
          <a className="anchor-box-margin" href="#portfolio1">
            EXPLORE
          </a>
        </div>
      </header>

      <Service />

      {/* <section className="container background-color-FFBF54 text-color-202529"> */}
      <Project />
      
      {/* <Testimonial /> */}
      <section className="page-section" id="client">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">
              CLIENTS & PARTNERS
            </h2>
            <hr style={{ backgroundColor: '#FFFFFF', width: '10rem' }}></hr>
          </div>
          <div className="row clientSectionOverrideStyle" style={{}}>
            <div className="col-lg-4 p-3">
              <img src="assets/img/Oslo-client-logo.svg"></img>
            </div>
            <div className="col-lg-4 p-3">
              <img src="assets/img/conluo-client-logo.svg"></img>
            </div>
            <div className="col-lg-4 p-3">
              <img src="assets/img/GAINIIT-client-logo.svg"></img>
            </div>
          </div>
        </div>
      </section>

      <Contact />

      <footer className="footer">
        <div className="container">
          <div
            className="align-items-start"
            style={{ background: '#252E35', color: '#FFFFFF' }}
          >
            <Footer />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
