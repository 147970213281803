import React, { useState } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

class Contact extends React.Component {
  state = {
    acknowledgePage: 'false',
  };
  constructor(props) {
    super(props);
    this.state = {
      acknowledgePage: 'false',
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
    this.setState({
      acknowledgePage: 'false',
    });
  };

  handleContinue = (event) => {
    event.preventDefault();

    var templateParams = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };

    emailjs
      .send(
        'service_s4tqq2k',
        'template_cw3avhv',
        templateParams,
        'user_mxNN7Wtj3cXlROZuhe3Om'
      )
      .then(
        (response) => {
          if (response.status == 200) {
            console.log('we will contact you soon');
            this.setState({ acknowledgePage: 'true' });
          }
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
  };

  render() {
    return (
      <>
        <span class="anchor" id="contact1"></span>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">Contact Us</h2>
              <hr style={{ backgroundColor: '#FFFFFF', width: '5rem' }}></hr>

              <h3 className="section-subheading text-muted">
                Share your details we will reach out to you
              </h3>
            </div>
            <div style={{ color: '#FFFFFF' }}>
              {' '}
              {this.state.acknowledgePage == 'true'
                ? 'We have got your email, we will get back to you soon'
                : ''}
            </div>
            <form id="contactForm" name="sentMessage">
              <div className="row" style={{ maxWidth: '90%', margin: 'auto' }}>
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="contact-labels">Name*</div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                      placeholder=""
                      required="required"
                      data-validation-required-message="Please enter your name."
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="form-group row">
                    <span
                      className="col-lg-6 col-sm-12"
                      style={{ padding: '0 0.7rem' }}
                    >
                      <div className="contact-labels">Contact number*</div>
                      <input
                        className="form-control"
                        id="phone"
                        type="tel"
                        name="phone"
                        onChange={this.handleChange}
                        placeholder=""
                        required="required"
                        data-validation-required-message="Please enter your phone number."
                      />
                      <p className="help-block text-danger"></p>
                    </span>
                    <span
                      className="col-lg-6 col-sm-12"
                      style={{ padding: '0 0.7rem' }}
                    >
                      <div className="contact-labels">Email*</div>
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        onChange={this.handleChange}
                        placeholder=""
                        required="required"
                        data-validation-required-message="Please enter your email address."
                      />
                      <p className="help-block text-danger"></p>
                    </span>
                  </div>
                  <div className="form-group form-group-textarea mb-md-0">
                    <div className="contact-labels">Message </div>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      onChange={this.handleChange}
                      placeholder="Please mention your available date and time for call back."
                      required="required"
                      data-validation-required-message="Please enter a message."
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="text-center">
                    <div id="success"></div>
                    <button
                      className="btn btn-primary btn-xl"
                      id="sendMessageButton"
                      onClick={this.handleContinue.bind(this)}
                      style={{ textAlign: 'left' }}
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </form>
          </div>
        </section>
      </>
    );
  }
}

export default Contact;
