import React from 'react';
import axios from 'axios';
import { graphqlapi, aboutSchema, infoSchema } from './config';
import content from './content.json';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      info: content['contact-us'],
    };
  }

  render() {
    return (
      <>
        {/* { this.state.data.map((list,index) => (
             <div className="row" style={{ textAlign: 'left', padding: '4rem' }}>
             <div className="col-md-3 p-3">
               <div
                 className="text-uppercase"
                 style={{ fontSize: '1.5rem', fontWeight: '600' }}
               >
                 DCX
               </div>
              {list.description}
             </div>
             <div className="col-md-3 p-3">
               <div
                 className="text-uppercase"
                 style={{ fontSize: '1.5rem', fontWeight: '600' }}
               >
                 Services
               </div>
               {list.service}
             </div>
            <div
               className="col-md-6 p-3"
               style={{ background: 'rgba(255, 255, 255, 0.06)' }}
             >
               <div
                 className="text-uppercase"
                 style={{ fontSize: '1.5rem', fontWeight: '600' }}
               >
                 About us
               </div>
               <div>
               {list.aboutus}
               </div>
             </div> 
           </div>
            ))}   */}

        <div className="row">
          <div
            className="col-lg-3 p-3"
            style={{ border: '1px solid rgba(255, 255, 255, 0.15)' }}
          >
            <img
              src="assets/img/DCX-logo.svg"
              alt=""
              style={{ margin: '2rem', width: '14rem' }}
            />
          </div>
          <div className="col-lg-3 p-3 borders-top-bottom-footer">
            <div className="footer-bottom-image">
              <img src="assets/img/location-vector.svg"></img>
            </div>
            <div className="footer-bottom-text">{this.state.info.location}</div>
          </div>
          <div className="col-lg-3 p-3 borders-top-bottom-footer">
            <div
              className="footer-bottom-image"
              style={{ marginBottom: '0.5rem' }}
            >
              <img src="assets/img/message-vector.svg"></img>
            </div>
            <div className="footer-bottom-text">{this.state.info.email}</div>
          </div>
          <div className="col-lg-3 p-3 borders-top-bottom-footer">
            <div className="footer-bottom-image">
              <img src="assets/img/phone-vector.svg"></img>
            </div>
            <div className="footer-bottom-text">{this.state.info.phone}</div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
