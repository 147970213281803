import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { graphqlapi, serviceSchema } from './config';
import axios from 'axios';
import content from './content.json';

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: content.services,
    };
  }

  render() {
    const classes = makeStyles((theme) => ({
      root: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '300',
        color: '#FFFFFF',
      },
    }));

    return (
      <>
        <span class="anchor" id="portfolio1"></span>
        <section className="show-mobile">
          <div
            className="text-center background-color-252E35"
            style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}
          >
            <h2 className="section-heading-mobile text-uppercase">Services</h2>
            <hr style={{ backgroundColor: '#ffffff', width: '4rem' }}></hr>
            <h3 className="section-subheading-mobile">
              Pick one or more, a delightful Xperience is guaranteed
            </h3>

            {/* service for mobile start */}
            {this.state.data.map((list, index) => (
              <Accordion key={index}>
                <div style={{ background: '#252E35', color: '#FFFFFF' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: '#FFFFFF' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className={classes.heading}
                      style={{ background: '252E35' }}
                    >
                      {' '}
                      {list.title}
                    </Typography>
                  </AccordionSummary>
                </div>
                <AccordionDetails className="accordion-dropdown-text">
                  <Typography>
                    <div className="service-hover-text-mobile">
                      {list.description}
                    </div>
                    {/* <a className="service-hover-read-more-mobile">READ MORE</a> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
            {/* for service for desktop  end*/}
          </div>
        </section>
        <section className="page-section bg-light show-large" id="portfolio">
          <div className="container">
            <div className="text-center background-color-252E35">
              <h2 className="section-heading text-uppercase">Services</h2>
              <hr style={{ backgroundColor: '#ffffff', width: '4rem' }}></hr>
              <h3 className="section-subheading text-muted">
                Pick one or more, a delightful Xperience is guaranteed
              </h3>
            </div>

            {/* for service for desktop  start*/}

            <div className="row background-color-252E35 m-0">
              {this.state.data.map((list, index) => (
                <div className="col-lg-4 col-sm-6 mb-4" key={index}>
                  <div className="portfolio-item">
                    <a
                      className="portfolio-link"
                      data-toggle="modal"
                      href="#portfolioModal1"
                    >
                      <div className="portfolio-hover">
                        <div className="portfolio-hover-content">
                          <div className="service-hover-heading">
                            {list.title}
                          </div>
                          <div className="service-hover-text">
                            {list.description}
                          </div>
                          {/* <a className="service-hover-read-more">READ MORE</a> */}
                        </div>
                      </div>
                      <div className="img-over-text">
                        <img
                          className="img-fluid"
                          src={list.image}
                          alt=""
                          style={{ height: '250px' }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span className="services-heading-hover">
                            {list.title}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              ))}
              {/* for service for desktop  end*/}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Service;
