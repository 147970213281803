import React from 'react';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';
import { graphqlapi, projectSchema } from './config';
import content from './content.json';

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: content.projects };
  }

  render() {
    // console.log(this.state.data[0])
    const length = this.state.data.length;
    var projectData = [];

    for (let i = 0; i < length; i++) {
      var nextIndex = (i + 1) % length;
      const dataList = {
        title: this.state.data[i].title,
        description: this.state.data[i].description,
        image: this.state.data[i].image,
        background: this.state.data[i].background,
        nextTitle: this.state.data[nextIndex].title,
      };
      projectData.push(dataList);
    }

    return (
      <>
        <section className="page-section " id="project">
          <div className="container">
            <Carousel indicators={false}>
              {projectData.map((list, index) => (
                <Carousel.Item
                  key={index}
                  style={{ background: `${list.background}` }}
                >
                  <div className="text-center text-color-202529">
                    <h2 className="section-heading text-uppercase">PROJECTS</h2>
                    <hr
                      style={{ backgroundColor: '#202529', width: '5rem' }}
                    ></hr>
                    <h3 className="section-subheading">
                      A glimpse of our work
                    </h3>
                  </div>
                  <div className="row" style={{ minHeight: '380px' }}>
                    <div
                      className="col-lg-10 text-left"
                      style={{ padding: '0 7%' }}
                    >
                      <h4>{list.title}</h4>
                      <div>
                        <p className="large project-image-section">
                          {list.description}
                        </p>
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-10">
                            <img
                              className="project-section-image"
                              src={list.image}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-2 up-next-block-ipad"
                      style={{ paddingLeft: '5rem' }}
                    >
                      <div className="up-next-block show-large">
                        <div style={{ fontWeight: '600', fontSize: '1.4rem' }}>
                          Up next
                        </div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1.6rem',
                            lineHeight: '135%',
                            paddingTop: '60%',
                          }}
                        >
                          {list.nextTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </section>
      </>
    );
  }
}

export default Project;
