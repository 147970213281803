import React from 'react'
import axios from 'axios'
import {graphqlapi,testimonialsSchema} from './config'


class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount(){

 

      axios.post(graphqlapi,{query: testimonialsSchema})
    .then(data => {
      this.setState({data: data.data.data.listTestimonials.data})
    })    
    .catch(error => console.log("Error: " + error))

}

 
  render() {
    return <>
     <section className="page-section" id="testimonials">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase text-color-202529">
              Testimonials
            </h2>
            <hr style={{ backgroundColor: '#202529', width: '5rem' }}></hr>
            <h3 className="section-subheading text-muted text-color-202529">
              See what our clients says about us
            </h3>
          </div>
          <div className="row text-center">
         { this.state.data.map((list,index) => (
            <div className="col-md-4 text-color-202529" key={index}>
              <p className="bubble speech">
               {list.testimonial}
              </p>
              <div className="team-member">
                <img
                  className="mx-auto rounded-circle"
                  src={list.image}
                  
                />
                <h4>{list.name}</h4>
                <p className="text-muted text-color-202529">
                  {list.designation}
                </p>
              </div>
            </div>
            ))}  
            
          </div>
        </div>
      </section>

    </>
      
      
  
  }
}

export default Testimonial
